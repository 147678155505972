import React, { useState, useEffect, useContext} from "react";
import Navbar from "../components/Navbar/Navbar";
import Menu from "../components/Menu/Menu";
import { useNavigate } from 'react-router-dom';
import Dashboard from "../components/MainContent/Dashboard";
import {Theme} from "../App"
import {SetNotificationCount} from '../App'
import { APP_URLS } from "../api/url";
import API from "../api/axios";


const DashboardPage = () => {

  
  const setNotification = useContext(SetNotificationCount);
  
  const mode = useContext(Theme);
  
  const navigate = useNavigate()
  const [values, setValues] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [menu,setMenu]=useState(false);
  
  const [profileUpdated,setProfileUpdated]=useState(false);
  
  const fetchWalletList = async () => {
    await API.get(APP_URLS.COIN_SWAP_APP)
        .then((result) => {
            const resp = result.data;
            localStorage.setItem('wallet_list', JSON.stringify(resp.data));
        })
        .catch((error) => console.log({ error }))
  }
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    const notification = localStorage.getItem('notification');
    setNotification(notification)
    if (token == '' || token === null || token === undefined) {
      navigate('/login')
    }
  }, []);

    useEffect(() => {
      fetchWalletList()
      const token = localStorage.getItem('token');
      if(token != '' && token !== null) {
      let authToken = localStorage.getItem("token");
      let access_type = localStorage.getItem("access_type");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", access_type + " " + authToken);
  
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      fetch(process.env.REACT_APP_API_URL+"user-dashboard-app?buycoin_type=weekly", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let data = JSON.parse(result);
          setValues(data);
          setDataLoaded(true);
          localStorage.setItem('notification',data.data.notification_count)
        })
        .catch((error) => console.log("error", error));
  }
    }, []);



  
  return (
    <>
      <div className={"flex flex-row " + (mode==='light' ? 'bg-grey' : 'bg-bgblue')}>
        <div className={"w-w17 " +(menu?'md:absolute sm:absolute ':'md:hidden sm:hidden')}>
          <Menu mode={mode} setMenu={setMenu} menu={menu} setProfileUpdated={setProfileUpdated}/>
        </div>
        <div className="w-w83 md:w-w100 sm:w-w100 sm:overflow-x-hidden px-3">
            <Navbar mode={mode} setMenu={setMenu} data={values}/>
          <div className="mt-10 flex flex-col items-center lg:mt-5 sm:mt-14">
          <Dashboard mode={mode} data={values} key={values} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;

