import React,{useState,useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownLong,
  faCircle,
  faImage,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import announcement from "../../../../components/Images/announcement.png";
import BankDeposit from './Forms/BankDeposit';
import CreditCard from './Forms/CreditCard';
import CoinPayment from './Forms/CoinPayment';
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';

const BuyOurCoin = (props) => {

  //Payment Methods
  const[paymentmethods,setPaymentmethods]=useState(Object.values(props.data.payment_methods))
  const [displayPaymentMethods,SetDisplayPaymentMethods] = useState();
  
  //Validation Coin Amount
  const [validateAmount,setValidateAmount]=useState(false)

  //Converted Coin Values 
  const [coinAmount,setCoinAmount] = useState('');
  const [convertedAmount,setConvertedAmount] = useState(props.data.btc_dlr);
  const [coinType,setCoinType] = useState(props.data.base_coin_type);
  const [coinPrice,setCoinPrice] = useState('10.0000');
  const [respAmount,setRespAmount] = useState(1);
  const [respBonus,setRespBonus] = useState(0);

  //Phase ID
  const [phaseID,setphaseID] = useState(null);

  //BUYCOINRATE API
  const buycoinrate =() =>{
    var formdata = new FormData();
    formdata.append("payment_type", coinType);
    formdata.append("amount", coinAmount);
    formdata.append("pay_type", "1");
    API.post(APP_URLS.BUY_COIN_RATE_APP,formdata)
    .then(res => { 
        if (res.data.success === true) {
          setConvertedAmount(res.data.data.btc_dlr)
          setRespAmount(res.data.data.amount)
          setCoinPrice(res.data.data.coin_price)
          setRespBonus(res.data.data.bonus)
        } 
      })
      .catch((error) => {
        const resp = error.response;
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
      })   
  }

  useEffect(() => {
    buycoinrate()
  }, [coinType,coinAmount])

  useEffect(()=>{
    {props.data.activePhase.status==true?setphaseID(props.data.activePhase.pahse_info.id):setphaseID(null)}
  },[])
  
  

  return (
    <div className={(props.mode==='light'?'bg-orange':'bg-elemblue2 ')+' shadow-lg p-7 rounded-lg justify-between items-center w-w50 mt-2 md:w-full'} >
    <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-left w-w100 text-medium mb-2'}>
      Buy Tokens Here By Card Or Crypto
    </h2>
    {props.data.activePhase.status===true?
      <div className="flex bg-greenalert text-white text-sm rounded-md w-full my-3" role="alert">
        <span className="flex bg-greenalertdark p-2 rounded-l-md mr-3">
          {" "}
          <img className='self-center' src={announcement} />
        </span>
        <p className="mr-4 py-3 px-2 self-center">
          New Ico Phase are available now. Now you can get some extra
          facility when buy coin
        </p>
      </div>:
      <div className="flex bg-redAlertLight text-white text-sm rounded-md w-full my-3" role="alert">
      <span className="flex bg-redAlert p-2 rounded-l-md mr-3">
        {" "}
        <FontAwesomeIcon icon={faTriangleExclamation} className="self-center text-white text-xlarge"/>
      </span>
      <p className="mr-4 py-3 px-2 self-center">
        New ICO Phases Will Be Announced Here.
      </p>
    </div>}
    <div className="w-full inline-block">
      <div className="flex flex-row items-center my-2">
        <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 xl:text-xs'}>
          Coin Amount
        </p>
        {props.data.activePhase.status&&(
        <>
        <p className="text-small text-red">*</p>
        <p className="text-xxsmall text-greylight ml-2">
          {" "}
          (Bonus = {respBonus} HDL)
        </p>
        </>
        )}
      </div>
      <form>
        <div className={
            (props.mode === "light"
              ? "bg-white"
              : "bg-inputblue1 text-white") +
            " flex w-full outline-none text-small border-btnblue border rounded-3xl h-h45 pl-4"}>
          <input
            type="number"
            name="coin-amount"
            placeholder='Enter coin amount'
            autoComplete='off'
            value={coinAmount}
            onChange={(e)=>setCoinAmount(e.target.value)}
            // onBlur={buycoinrate}
            className={
              (props.mode === "light"
                ? "bg-white"
                : "bg-inputblue1 text-white") +
              " w-full outline-none text-small p-1 rounded-3xl h-h42 px-1"
            }
          />
          <span className='flex min-w-max self-center h-h42 px-2 opacity-50 border-l border-lightblue sm:min-w-w30 sm:hidden'><b className='mx-auto px-2 self-center text-xsmall '>{parseFloat(coinPrice).toFixed(2)} USD</b></span>
        </div>
        {props.data.activePhase.status==true?
        <p className="text-xsmall text-greylight mr-2 mt-1 text-left pl-2">
          <span className="mr-2">({parseFloat(props.data.coin_price).toFixed(4)}X{respAmount} = <b>{parseFloat(coinPrice).toFixed(2)} USD</b>)</span>{" "}
          <span className="mr-2">(${parseFloat(coinPrice).toFixed(2)} = {convertedAmount} {coinType})</span>{" "}
        </p>:
        <p className="text-xsmall text-greylight mr-2 mt-1 text-left pl-2">
          <span className="mr-2">({props.data.coin_price}X{respAmount} = {parseFloat(coinPrice).toFixed(2)} USD)</span>{" "}
          <span className="mr-2">(${parseFloat(coinPrice).toFixed(2)} USD = {convertedAmount} {coinType})</span>{" "}
        </p>}
        {/* {validateAmount?<div className='text-left text-red text-xs pt-2'>Coin Must be greater than 0.00000010</div>:null} */}
      </form>
    </div>
    <div className="mt-4 text-left">
      <h3 className={(props.mode==='light'?'text-darkblue':'text-white')+' mb-3'}>Payment Type</h3>
      <form className="flex mb-5 sm:block">
        {paymentmethods.map((item,index)=>{
          return (
            <label className={(props.mode==='light'?'text-darkblue':'text-white')+' flex items-center space-x-2 mr-4 sm:mb-2'} key={index}>
            <input
              type="radio"
              name="checked-demo"
              value={item}
              className="h-5 w-5 border border-gray-400 rounded-full checked:bg-gray-900 checked:border-transparent focus:outline-none"
              onChange={()=>SetDisplayPaymentMethods(item)}
            />
            <span className={(props.mode==='light'?' opacity-80':'opacity-40')+' text-sm'}>{item}</span>
          </label>
          )
        })}
      </form>
    </div>

    {/*payment options start*/}
    <hr className='border-lightblue border-opacity-20 mt-10 mb-5' />
          <div className={(props.mode=='light'?'bg-orange':'bg-elemblue2')+' w-full flex-col w-full pb-5 rounded-lg'}>
            {/* coin payment start */}
            {displayPaymentMethods==="Coin Payment"&&(
              <CoinPayment mode={props.mode} coinAmount={coinAmount} convertedAmount={convertedAmount} data={props.data} setCoinAmount={setCoinAmount} setConvertedAmount={setConvertedAmount} setCoinType={setCoinType} setValidateAmount={setValidateAmount} SetDisplayPaymentMethods={SetDisplayPaymentMethods} phaseID={phaseID}/>
            )}
            {/* coin payment end */}

            {/* bank deposit start */}
            {displayPaymentMethods==="Bank Deposit"&&(
              <BankDeposit mode={props.mode} coinAmount={coinAmount} data={props.data} setCoinAmount={setCoinAmount} setValidateAmount={setValidateAmount} SetDisplayPaymentMethods={SetDisplayPaymentMethods} phaseID={phaseID}/>
            )}
            {/* bank deposit end */}

            {/* credit card start */}
            {displayPaymentMethods==="Credit Card"&&(
              <CreditCard mode={props.mode} coinAmount={coinAmount} data={props.data} setCoinAmount={setCoinAmount} setValidateAmount={setValidateAmount} SetDisplayPaymentMethods={SetDisplayPaymentMethods} phaseID={phaseID}/>
            )}
            {/* credit card end */}
          </div>

    {/*payment options end*/}

  </div>
  )
}

export default BuyOurCoin