import React,{useContext,useState,useEffect} from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { APP_URLS } from "../../../../../api/url";
import API from "../../../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';
import { Stripe } from './StripeToken/Stripe';
import { NewEntries } from '../../../../BuyCoins';



const CreditCard = (props) => {
  let coins = parseFloat(props.coinAmount)
  let pkey = props.data.settings.STRIPE_KEY
  const setNewEntries = useContext(NewEntries);
  const [loading, setLoading] = useState(false);
  const [coinAmount, setCoinAmount] = useState(props.coinAmount)

  useEffect(() => {
    setCoinAmount(props.coinAmount)
  },[props.coinAmount])


  
  const formik = useFormik({
    initialValues: {
      coinAmount:coins,
      cardholdername:'',
      cardnumber:'',
      cvc:'',
      expiremonth:'',
      expireyear:'',
      payment_type:5
    },
    validationSchema: Yup.object({
      cardholdername: Yup.string().required("Card Holder Name is required"),
      cardnumber: Yup.string().min(16,"Card Number must be at least 16 characters").max(16,"Card Number must be at most 16 characters").required("Card Number can't be blank"),
      cvc: Yup.string().min(3,"CVC must be at least 3 characters").max(4,"CVC must be at most 4 characters").required("CVC is required"),
      expiremonth: Yup.string().min(2,"Expire Month must be at least 2 characters").max(2,"Expire Month must be at most 2 characters").required("Expire Month is required"),
      expireyear: Yup.string().min(4,"Expire Year must be at least 4 characters").max(4,"Expire Year must be at most 4 characters").required("Expire Year is required"),
    }),
    onSubmit: async values => {
      if(!loading) { 
        setLoading(true)
      const payload ={
        number:values.cardnumber,
        cvv:values.cvc,
        exp_month:values.expiremonth,
        exp_year:values.expireyear,
        name:values.cardholdername,
        key:pkey
      }
      const token = await Stripe.getCreditCardToken(payload);
      if (token.error) {
        toast.error(token.error.message);
        setLoading(false)
        return;
      } 
      let formValues = {}
      const form = new FormData(); 
      // formValues[`payment_type`] = values.payment_type
      // formValues[`coin`] = coins
      // formValues[`stripeToken`] = token.id
      form.append('payment_type', values.payment_type);
      form.append('coin', coins);
      form.append('stripeToken', token.id);
      if(props.phaseID!=null){
        // formValues[`phase_id`] = props.phaseID
        form.append("phase_id", props.phaseID)
      }
      API.post(APP_URLS.BUY_COIN_THROUGH_APP, form)
      .then(res => {
          setLoading(false)                         
          props.setCoinAmount(0)
          if (res.data.success === true) {
            setNewEntries(true)
            formik.resetForm()
            toast.success('Transaction has been done successfully.', {
              position: toast.POSITION.TOP_RIGHT
            })
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT
            })        
          }
        })
        .catch((error) => {
          setLoading(false)
          const resp = error.response;
          if (resp) {
            
              let error_message = '';
              if (resp.data.errors !== undefined) {
                  {Object.keys(resp.data.errors).map((error, index) => (
                      error_message = resp.data.errors[error][0]
                  ))}
              } else if (resp.data.data.error !== undefined) {
                  error_message =  resp.data.data.error;
              } else {
                  error_message =  resp.data.error
              }
  
              toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT
              })
          }
        })  
    }
  }
  });

  useEffect(() => {
    {coinAmount<=0?props.setValidateAmount(true):props.setValidateAmount(false)}
  }, [coinAmount])

  const handleInput =(val)=>{
    let str=val+""
    var temp = document.getElementById("cardNumber");
    if(str.length>15){
      temp.type= "text"
      if (temp.setSelectionRange) {
        temp.focus();
        temp.setSelectionRange(str.length, str.length);      
      } else if (temp.createTextRange) {
        var range = temp.createTextRange();
        range.collapse(true);
        range.moveEnd('character', str.length);
        range.moveStart('character', str.length);
        range.select();
      }
    }
    else{
      temp.type = "number"
    }
  }

  const handleCvc =(val)=>{
    let str=val+""
    var temp = document.getElementById("cvc");
    if(str.length>3){
      temp.type= "text"
      if (temp.setSelectionRange) {
        temp.focus();
        temp.setSelectionRange(str.length, str.length);      
      } else if (temp.createTextRange) {
        var range = temp.createTextRange();
        range.collapse(true);
        range.moveEnd('character', str.length);
        range.moveStart('character', str.length);
        range.select();
      }
    }
    else{
      temp.type = "number"
    }
  }

  return (
    <>
    <ToastContainer />
    <form onSubmit={formik.handleSubmit}>
        <div className={(props.mode=='light'?'bg-orange':'bg-elemblue2')+' w-full flex-col w-full pb-5 rounded-lg'}>
        {/* Credit Card start */}
        <div className='w-full'>
                  <div className='w-full mt-4'>
                    <div className='flex flex-row items-center my-2'>
                      <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 xl:text-xs'}>Name on Card</p>
                      <p className='text-small text-red'>*</p>
                    </div>
                    <div>                             
                    <input 
                    type="text" 
                    name="cardholdername"
                    autoComplete='off'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cardholdername}
                    autofill="off"
                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.cardholdername && formik.errors.cardholdername?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small p-1 h-h45 px-4 shadow-lg"} />   
                    {formik.touched.cardholdername && formik.errors.cardholdername ? (
                    <div className='text-left text-red text-xs pt-2 pl-2'>Card Holder can't be blank</div>):null}                    
                    </div> 
                  </div>  

                  <div className='w-full mt-4'>
                    <div className='flex flex-row items-center my-2'>
                      <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 xl:text-xs'}>Card Number</p>
                      <p className='text-small text-red'>*</p>
                    </div>
                    <div>                             
                    <input 
                    type="number" 
                    name="cardnumber"
                    id="cardNumber"
                    autoComplete='off'
                    maxLength='16'
                    onChange={(e)=>{
                      formik.handleChange(e)
                      handleInput(e.target.value)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.cardnumber}
                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.cardnumber && formik.errors.cardnumber?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small p-1 h-h45 px-4 shadow-lg"} />        
                    {formik.touched.cardnumber && formik.errors.cardnumber ? (
                    <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.cardnumber}</div>):null}
               
                    </div> 
                  </div> 

                  <div className='flex flex-row gap-5 lg:gap-3 sm:block'>
                    <div className='mt-4 w-w33 sm:w-full'>
                      <div className='flex flex-row items-center my-2'>
                        <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 xl:text-xs'}>CVC</p>
                        <p className='text-small text-red'>*</p>
                      </div>
                      <div>                             
                      <input 
                      type='number' 
                      autoComplete='off'
                      name='cvc'
                      id="cvc"
                      onChange={(e)=>{
                        formik.handleChange(e)
                        handleCvc(e.target.value)
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.cvc} 
                      placeholder='Ex. 123'
                      maxLength="4"
                      className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.cvc && formik.errors.cvc?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small p-1 h-h45 px-4 shadow-lg"} /> 
                      {formik.touched.cvc && formik.errors.cvc ? (
                      <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.cvc}</div>):null}                      
                      </div> 
                    </div> 
                    <div className='mt-4 w-w33 sm:w-full'>
                      <div className='flex flex-row items-center my-2'>
                        <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 xl:text-xs'}>Expiration Month</p>
                        <p className='text-small text-red'>*</p>
                      </div>
                      <div>                             
                      <input 
                      type='text' 
                      name='expiremonth' 
                      placeholder='MM'
                      autoComplete='off'
                      maxLength="2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.expiremonth} 
                      className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.expiremonth && formik.errors.expiremonth?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small p-1 h-h45 px-4 shadow-lg"} /> 
                      {formik.touched.expiremonth && formik.errors.expiremonth ? (
                      <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.expiremonth}</div>):null}                      
                      </div> 
                    </div> 
                    <div className='mt-4 w-w33 sm:w-full'>
                      <div className='flex flex-row items-center my-2'>
                        <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 xl:text-xs'}>Expiration Year</p>
                        <p className='text-small text-red'>*</p>
                      </div>
                      <div>                             
                      <input 
                      type='text' 
                      name='expireyear' 
                      placeholder='YYYY'
                      autoComplete='off'
                      maxLength="4"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.expireyear} 
                      className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.expireyear && formik.errors.expireyear?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small p-1 h-h45 px-4 shadow-lg"} /> 
                      {formik.touched.expireyear && formik.errors.expireyear ? (
                      <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.expireyear}</div>):null}                      
                      </div> 
                    </div> 
                  </div>                                  
                </div>
        </div>
        {/* Credit Card end */}
        {coinAmount<=0?
        <div className="w-full flex">
          <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+' justify-start mt-3 rounded-3xl w-wp160 h-h13 text-white disabled:opacity-50'} disabled>
            Buy Now!
          </button>
        </div>:
        <div className="w-full flex">
          <button type="submit" className= {(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+" justify-start mt-3 rounded-3xl w-wp160 h-h13 text-white disabled:opacity-50"} disabled={loading}>
            {loading?'Please Wait':'Buy Now!'}
          </button>
        </div>
        }
    </form>
    </>
  )
}

export default CreditCard